const OPEN = 1;

const AudioContext =
    window.AudioContext ||
    window.webkitAudioContext || // Safari and old versions of Chrome
    null;

export default class AudioProcessor {
    constructor(webSocketUri) {
        this._webSocketUri = webSocketUri;
    }

    start = ({ onTrans }) => {
        let stream = null;
        let websocket = null;
        let sentDetails = false;

        if (!navigator.mediaDevices) {
            console.warn("navigator.mediaDevices is not supported");
            return null;
        }

        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then((newStream) => {
                stream = newStream;
                console.log("stream started");

                websocket = _initWebSocket({
                    webSocketUri: this._webSocketUri,
                    onMessage: (e) => {
                        console.log("message", e);
                        onTrans && onTrans(JSON.parse(e.data));
                    },
                    onOpen: () => {
                        const context = new AudioContext();
                        const source = context.createMediaStreamSource(stream);
                        const processor = context.createScriptProcessor(
                            4096,
                            1,
                            1
                        );

                        source.connect(processor);
                        processor.connect(context.destination);

                        processor.onaudioprocess = function (e) {
                            if (!websocket || websocket.readyState !== OPEN) {
                                return;
                            }

                            if (!sentDetails) {
                                websocket.send(
                                    JSON.stringify({
                                        rate: e.inputBuffer.sampleRate,
                                        littleEndian: _isLittleEndianHardware(),
                                    })
                                );
                                sentDetails = true;
                            }

                            websocket.send(e.inputBuffer.getChannelData(0));
                        };

                        source.onended = function () {
                            source.disconnect(processor);
                            processor.disconnect(context.destination);
                        };
                    },
                    onClose: () => {
                        console.log("onClose");
                        if (stream) {
                            _stopStream(stream);
                            stream = null;
                        }
                        websocket = null;
                    },
                });
            });

        return {
            stop: () => {
                console.log("stopping stream");
                stream && _stopStream(stream);
                if (websocket) {
                    if (websocket.readyState === OPEN) {
                        websocket.send("END");
                    }
                    websocket.close();
                }
            },
        };
    };
}

function _initWebSocket({ webSocketUri, onOpen, onMessage, onClose }) {
    var websocket = new WebSocket(webSocketUri);
    websocket.onopen = function () {
        console.log("Connected");
        onOpen && onOpen();
    };
    websocket.onclose = function () {
        console.log("Closed");
        onClose && onClose();
    };
    websocket.onerror = function (e) {
        console.log("Error", e);
    };
    websocket.onmessage = (e) => onMessage(e);
    return websocket;
}

function _stopStream(stream) {
    stream.getTracks().forEach((track) => track.stop());
}

function _isLittleEndianHardware() {
    var arrayBuffer = new ArrayBuffer(2);
    var uint8Array = new Uint8Array(arrayBuffer);
    var uint16array = new Uint16Array(arrayBuffer);
    uint8Array[0] = 0xaa; // set first byte
    uint8Array[1] = 0xbb; // set second byte
    return uint16array[0] === 0xbbaa; // little endian
}
